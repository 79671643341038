import React from "react";
import bannerBg from "../../assets/img/man-with-cap.png";

const BestFeatures = () => {
  return (
    <section className="best-featured-wrapper section-padding pb-5">
      <div className="container">
        <div className="row mtm-40 align-items-center">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="single-best-featured right">
              <div className="icon">
                <i className="flaticon-flow-chart" />
              </div>
              <div className="content-text">
                <h5>Comprehensive Planning</h5>
                <p>
                  Each project begins with detailed planning, utilizing
                  state-of-the-art tools to map out every phase. This meticulous
                  approach minimizes risks and ensures seamless execution and
                  timely delivery.
                </p>
              </div>
            </div>
            <div className="single-best-featured right">
              <div className="icon">
                <i className="flaticon-writing" />
              </div>
              <div className="content-text">
                <h5>Customized Solutions</h5>
                <p>
                  We tailor our construction solutions to meet the specific
                  needs of each client, ensuring flexibility and satisfaction in
                  achieving the desired project outcomes.
                </p>
              </div>
            </div>
            <div className="single-best-featured right">
              <div className="icon">
                <i className="flaticon-interior-design" />
              </div>
              <div className="content-text">
                <h5>Trust</h5>
                <p>
                  2brothers Construction has earned the trust of clients through
                  consistent reliability, integrity, and transparency in all our
                  business dealings.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 p-md-0 mt-5 mb-5 mt-md-0 mb-md-0 col-md-6 col-12">
            <div className="center-img text-center mbm-50">
              <img src={bannerBg} alt="" />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="single-best-featured">
              <div className="icon">
                <i className="flaticon-secure-shield" />
              </div>
              <div className="content-text">
                <h5>Innovative Construction Methods</h5>
                <p>
                  2brothers Construction leverages innovative construction
                  methods and modern technology to enhance project execution and
                  outcomes. This ensures that each build is completed with the
                  utmost precision.
                </p>
              </div>
            </div>
            <div className="single-best-featured">
              <div className="icon">
                <i className="flaticon-team" />
              </div>
              <div className="content-text">
                <h5>Skilled Team</h5>
                <p>
                  Our team consists of highly skilled professionals who bring
                  expertise and craftsmanship to every project, ensuring
                  top-quality results every time.
                </p>
              </div>
            </div>
            <div className="single-best-featured">
              <div className="icon">
                <i className="flaticon-profits" />
              </div>
              <div className="content-text">
                <h5>Competitive Pricing</h5>
                <p>
                  We offer competitive pricing models that provide value for
                  money, making high-quality construction services affordable
                  and accessible.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BestFeatures;
