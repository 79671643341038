import React from "react";
import icon1 from "../assets/img/icons/t1.png";
import icon2 from "../assets/img/icons/t2.png";

const Timeline = () => {
  return (
    <section className="company-timeline-wrapper section-padding">
      <div className="container">
        <div className="row mtm-40">
          <div className="col-lg-4  col-md-6 offset-lg-1 pe-lg-0">
            <div className="timeline-content text-lg-end">
              <div className="single-timeline-box">
                <span>1994 - Foundation</span>
                <h6>Company Inception</h6>
                <p>
                  In 1994, 2brothers Construction was established by two
                  ambitious siblings, setting the stage for innovative and
                  tailored construction solutions.
                </p>
              </div>
              <div className="single-timeline-box">
                <span>1994 - Early Years</span>
                <h6>Building a Reputation</h6>
                <p>
                  Starting with a strong commitment to quality, the company
                  quickly became known for excellence, building trust with every
                  project delivered.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 d-none mt-40 d-lg-block">
            <div className="time-line-icon">
              <div className="single-icon">
                <img src={icon1} alt="" />
              </div>
              <div className="single-icon">
                <img src={icon2} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 ps-lg-0">
            <div className="timeline-content">
              <div className="single-timeline-box">
                <div className="single-timeline-box">
                  <span>2010 - 2020</span>
                  <h6>Technological Advancements</h6>
                  <p>
                    Over the decade, 2brothers Construction greatly enhanced its
                    innovative technologies and adopted digital tools to boost
                    building precision and efficiency.
                  </p>
                </div>
              </div>
              <div className="single-timeline-box">
                <span>2020 - 2024</span>
                <h6>Resilience and Innovation Through Adversity</h6>
                <p>
                  During COVID-19, 2brothers Construction adapted swiftly,
                  ensuring growth and innovation despite global disruptions.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-lg-5 mt-3">
          <div className="col-md-6 col-12 col-lg-4">
            <div className="single-icon-border-box">
              <div className="icon">
                <i className="flaticon-trust"></i>
              </div>
              <div className="content">
                <h6>
                  <span>Trusted</span> 
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 col-lg-4">
            <div className="single-icon-border-box">
              <div className="icon">
                <i className="flaticon-cashier-machine"></i>
              </div>
              <div className="content">
                <h6>
                  <span>Secured</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 col-lg-4">
            <div className="single-icon-border-box">
              <div className="icon">
                <i className="flaticon-goal"></i>
              </div>
              <div className="content">
                <h6>
                  <span>quality</span> 
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Timeline;
