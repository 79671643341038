import React from "react";
import { Link } from "react-router-dom";

import servicesTwoData from "./servicesTwoData";

const Details = ({ slug }) => {
  const service = servicesTwoData.find((service) => service.slug === slug);
  console.log(service);

  if (!service) {
    return <p>Service not found</p>;
  }

  return (
    <section className="service-details-wrapper section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 mt-5 mt-md-0 col-12 order-2 order-md-1">
            <div className="service-details-sidebar">
              <div className="single-service-sidebar">
                <div className="sidebar-title">
                  <h3>Other Services</h3>
                </div>
                <ul>
                  {servicesTwoData.map((service, index) => (
                    <li className="list-item">
                      <Link
                        to={
                          service.title === "Many more!"
                            ? ""
                            : `/servicesDetails/${service.slug}`
                        }
                      >
                        0{index}. {service.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              {/* <div className="single-service-sidebar">
                <div className="sidebar-title">
                  <h3>Download Brochures</h3>
                </div>
                <div className="brochures-download">
                  <a href="#" className="theme-btn">
                    <i className="fal fa-file-pdf" />
                    Download .PDF
                  </a>
                  <a href="#" className="theme-btn">
                    <i className="fal fa-file-word" />
                    Download .DOC
                  </a>
                  <a href="#" className="theme-btn">
                    <i className="fal fa-file-powerpoint" />
                    Download .PPT
                  </a>
                </div>
              </div> */}
              <div className="single-service-sidebar site_info_widget">
                <div className="sidebar-title">
                  <h3>Contact Us</h3>
                </div>
                <div className="contact-us">
                  <div className="single-contact-info">
                    <div className="icon">
                      <i className="fal fa-phone" />
                    </div>
                    <div className="contact-info">
                      <span>Phone Number</span>
                      <p>(289) 887 4695</p>
                    </div>
                  </div>
                  <div className="single-contact-info">
                    <div className="icon">
                      <i className="fal fa-envelope" />
                    </div>
                    <div className="contact-info">
                      <span>Email Address</span>
                      <p>info@2brothers.ca</p>
                    </div>
                  </div>
                  <div className="single-contact-info">
                    <div className="icon">
                      <i className="fal fa-map-marker-alt" />
                    </div>
                    <div className="contact-info">
                      <span>Address</span>
                      <p>78 kenilworth ave N, Hamilton</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-8 ps-lg-5 col-12 order-1 order-md-2">
            <div className="service-details-content-wrapper pl-0 pl-md-4">
              <img src={service.img} alt="service_image" />
              {service.detail_content}
              <h2>{service.detail_title}</h2>
              <p>{service.detail_paragraph}</p>
              <h2>{service.quality_title}</h2>
              <p>{service.quality_paragraph}</p>
              <div className="row">
                <div className="col-md-6 col-12">
                  <img src={service.img2} alt="" />
                </div>
                <div className="col-md-6 col-12">
                  <img src={service.img3} alt="" />
                </div>
              </div>
              <h2>{service.what_you_receive_title}</h2>
              <p>{service.what_you_receive_paragraph}</p>
              <blockquote>{service.quote}</blockquote>
            </div>
            <div className="faq-content pl-0 pl-md-4">
              <h2>Common Question for this project</h2>
              <div className="faq-accordion">
                <div className="accordion no-border me-lg-5" id="accordion">
                  {service.faqArray.map((faq, index) => (
                    <div className="accordion-item">
                      <h4 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#faq${index}`}
                          aria-expanded="false"
                          aria-controls="faq1"
                        >
                          {faq.question}
                        </button>
                      </h4>
                      <div
                        id={`faq${index}`}
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordion"
                      >
                        <div className="accordion-body">{faq.answer}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Details;
