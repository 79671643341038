import React from 'react'
import FooterTwo from '../Components/Footer/FooterTwo'
import HeaderOne from '../Components/Header/HeaderOne'
import bannerBg from '../assets/img/contact-banner.png';
import PageBanner from '../Components/PageBanner';
import BrandCarouselThree from "../Components/Brands/BrandCarouselThree";
import ContactPageContents from "../Components/Contact/ContactPageContents";
import HeaderTwo from '../Components/Header/HeaderTwo';

const ContactPage = () => {
  return (
    <>
        <HeaderTwo/>
        <PageBanner title='Contact Us' bannerBg={bannerBg} currentPage='contact' />
        <ContactPageContents/>
        {/* <BrandCarouselThree/> */}
        <FooterTwo/>
    </>
  )
}

export default ContactPage;
