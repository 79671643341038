import videoBg from "../../assets/img/video_bg_1.jpg";
import imgBlock1 from "../../assets/img/home2/img-block1.png";
import tabImg1 from "../../assets/img/home1/tab-img.jpg";
import { Link } from "react-router-dom";
import { useState } from "react";
import "react-modal-video/scss/modal-video.scss";
import ModalVideo from "react-modal-video";

const AboutOne = ({ pt }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="EZ9DrY43wtw"
        onClose={() => setOpen(false)}
      />
      <section
        className={
          pt
            ? "about-section section-padding"
            : "about-section section-padding pt-0"
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-5 col-12">
              <div className="img-block-pop-video">
                <img src={imgBlock1} alt="busico" />
                <div
                  className="popup-video-block d-flex justify-content-center align-items-center bg-cover"
                  style={{ backgroundImage: `url(${videoBg})` }}
                >
                  {/* <div className="video-play-btn">
                    <span
                      className="popup-video"
                      onClick={() => setOpen(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="fas fa-play"></i>
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7 col-md-10 col-12">
              <div className="block-contents">
                <div className="section-title">
                  <span>01. About Company</span>
                  <h2>Building With Integrity and Innovation</h2>
                </div>
                <p>
                  At 2brothers Construction, we pride ourselves on not just
                  constructing buildings, but creating spaces that enhance
                  lives. With over 20 years of experience, our commitment to
                  quality craftsmanship and sustainable building practices has
                  made us a trusted leader in the industry. Each project is a
                  reflection of our dedication to delivering exceptional results
                  while actively listening to our clients' needs, ensuring that
                  each structure is not only aesthetically pleasing but
                  functionally excellent.
                </p>
              </div>
              <div className="tab-content-block">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-technology-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-technology"
                      type="button"
                      role="tab"
                      aria-controls="pills-technology"
                      aria-selected="true"
                    >
                      Technology
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-materials-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-materials"
                      type="button"
                      role="tab"
                      aria-controls="pills-materials"
                      aria-selected="false"
                    >
                      Materials
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-approved-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-approved"
                      type="button"
                      role="tab"
                      aria-controls="pills-approved"
                      aria-selected="false"
                    >
                      Sustainability
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-technology"
                    role="tabpanel"
                    aria-labelledby="pills-technology-tab"
                  >
                    <div className="tab-inner-contents">
                      <div className="img-box">
                        {/* <img src={tabImg1} alt="" /> */}
                      </div>
                      <div className="checked-features-list">
                        <ul>
                          <ul>
                            <li>State-of-the-art construction technology</li>
                            <li>Advanced project management tools</li>
                            <li>Innovative design and engineering solutions</li>
                          </ul>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-materials"
                    role="tabpanel"
                    aria-labelledby="pills-materials-tab"
                  >
                    <div className="tab-inner-contents">
                      <div className="img-box">
                        {/* <img src={tabImg1} alt="" /> */}
                      </div>
                      <div className="checked-features-list">
                        <ul>
                          <li>High-quality, durable materials</li>
                          <li>Eco-friendly and sustainable options</li>
                          <li>Materials sourced from trusted suppliers</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-approved"
                    role="tabpanel"
                    aria-labelledby="pills-approved-tab"
                  >
                    <div className="tab-inner-contents">
                      <div className="img-box">
                        {/* <img src="assets/img/home1/tab-img.jpg" alt="" /> */}
                      </div>
                      <div className="checked-features-list">
                        <ul>
                          <li>Green building certifications</li>
                          <li>Energy-efficient building designs</li>
                          <li>Reduction of carbon footprint in projects</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Link to="/about" className="plus-text-btn mt-lg-5 mt-4">
                <div className="icon">
                  <i className="fas fa-plus"></i>
                </div>
                <div className="link-text">
                  <span>Explore</span> More about Us
                </div>
              </Link> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutOne;
