import React from "react";
import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderOne from "../Components/Header/HeaderOne";
import bannerBg from "../assets/img/page-banner.jpg";
import PageBanner from "../Components/PageBanner";
import Details from "../Components/Services/Details";
import Banner from '../assets/img/home2/services/page-banner.png'

import { useParams } from "react-router-dom";
import HeaderTwo from "../Components/Header/HeaderTwo";

const ServiceDetails = () => {
  const { slug } = useParams();

  console.log('slug',slug);
  return (
    <>
      <HeaderTwo />
      <PageBanner
        title={slug}
        bannerBg={Banner}
        currentPage={slug}
      />
      <Details slug={slug} />
      <FooterTwo />
    </>
  );
};

export default ServiceDetails;
