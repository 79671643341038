import { v4 as uuidv4 } from "uuid";
import img1 from "../../assets/img/home2/s1.jpg";
import demo1 from "../../assets/img/home2/services/demolition.png";
import demo2 from "../../assets/img/home2/services/demo2.png";
import siding1 from "../../assets/img/home2/services/siding1.png";
import siding2 from "../../assets/img/home2/services/siding2.png";
import framing1 from "../../assets/img/home2/services/framing1.png";
import framing2 from "../../assets/img/home2/services/framing2.png";
import roofing1 from "../../assets/img/home2/services/roofing1.png";
import roofing2 from "../../assets/img/home2/services/roofing2.png";
import interior1 from "../../assets/img/home2/services/interior1.png";
import interior2 from "../../assets/img/home2/services/interior2.png";
import concrete1 from "../../assets/img/home2/services/concrete1.png";
import concrete2 from "../../assets/img/home2/services/concrete2.png";
import img2 from "../../assets/img/home2/s2.jpg";
import img3 from "../../assets/img/home2/s3.jpg";
import roofing from "../../assets/img/home2/roofing.jpg";
import interior from "../../assets/img/home2/interior.jpg";
import concrete from "../../assets/img/home2/concrete.jpg";

const servicesTwoData = [
  {
    id: uuidv4(),
    img: img1,
    icon: "demolition",
    title: "Demolition Services",
    desc: "Expert demolition with a focus on safety and efficiency, paving the way for new projects and renovations.",
    slug: "demolition",
    detail_title: "Safe and Efficient Demolition",
    detail_paragraph: "Our demolition services ensure safety and efficiency, making way for new projects with minimal disruption. We use the latest techniques and equipment to handle any demolition job.",
    quality_title: "Quality Construction",
    quality_paragraph: "Our demolition projects are carried out with precision and care, ensuring that the surrounding structures are unharmed and the site is cleared efficiently.",
    what_you_receive_title: "What You Receive?",
    what_you_receive_paragraph: "Comprehensive demolition services including site preparation, debris removal, and material recycling. We focus on safety and efficiency, ensuring minimal disruption to your project timeline.",
    quote: "From initial planning to final debris removal, our demolition services are designed to meet your needs safely and efficiently.",
    img2: demo1,
    img3: demo2,
    faqArray: [
      {
        question: "What safety measures do you take during demolition?",
        answer: "We follow strict safety protocols and use advanced equipment to ensure a safe demolition process."
      },
      {
        question: "How long does a typical demolition project take?",
        answer: "The duration varies depending on the project's size and complexity, but we strive to complete all demolitions efficiently."
      },
      {
        question: "Do you handle debris removal after demolition?",
        answer: "Yes, we manage all aspects of debris removal and recycling, ensuring a clean site for your new project."
      },
      {
        question: "What types of structures can you demolish?",
        answer: "We can demolish residential, commercial, and industrial structures with equal expertise and precision."
      }
    ]
  },
  {
    id: uuidv4(),
    img: img2,
    icon: "siding",
    title: "Siding Installation",
    desc: "High-quality siding services to protect and beautify your home or office, using the best materials available.",
    slug: "siding",
    detail_title: "Protect and Beautify Your Property",
    detail_paragraph: "Our siding installation services use top-grade materials to enhance the protection and appearance of your home or office. We ensure a perfect fit and finish for lasting durability.",
    quality_title: "Quality Construction",
    quality_paragraph: "We use only the best materials for our siding projects, ensuring long-lasting protection and beauty for your property.",
    what_you_receive_title: "What You Receive?",
    what_you_receive_paragraph: "Professional siding installation that improves the insulation and aesthetic appeal of your building. Our expert team ensures a flawless finish and timely completion.",
    quote: "Protect and beautify your home or office with our expert siding installation services, tailored to your needs.",
    img2: siding1,
    img3: siding2,
    faqArray: [
      {
        question: "What materials do you use for siding?",
        answer: "We use high-quality materials such as vinyl, wood, and fiber cement to ensure durability and aesthetic appeal."
      },
      {
        question: "How long does siding installation take?",
        answer: "The installation time varies depending on the size of the building, but we aim to complete projects efficiently without compromising quality."
      },
      {
        question: "Can you match the existing siding of my home?",
        answer: "Yes, we can match the new siding with your existing siding to ensure a seamless look."
      },
      {
        question: "Do you offer maintenance services for siding?",
        answer: "Yes, we offer maintenance services to keep your siding in excellent condition for years to come."
      }
    ]
  },
  {
    id: uuidv4(),
    img: img3,
    icon: "framing",
    title: "Framing & Structural Work",
    desc: "Precision framing that ensures the structural integrity and longevity of your building projects.",
    slug: "framing",
    detail_title: "Precision Framing for Structural Integrity",
    detail_paragraph: "Our framing services provide the essential support needed for your building projects. We focus on precision to ensure the structural integrity and longevity of your construction.",
    quality_title: "Quality Construction",
    quality_paragraph: "We prioritize precision and strength in our framing projects, ensuring that your structure is built to last.",
    what_you_receive_title: "What You Receive?",
    what_you_receive_paragraph: "Detailed and robust framing services that form the backbone of your construction projects. Our team ensures that every beam and support is installed with precision to provide maximum stability and safety.",
    quote: "Ensure the strength and durability of your building with our expert framing and structural services.",
    img2: framing1,
    img3: framing2,
    faqArray: [
      {
        question: "What types of framing do you offer?",
        answer: "We offer various types of framing including wood, steel, and concrete to suit your construction needs."
      },
      {
        question: "How do you ensure the structural integrity of the framing?",
        answer: "We follow industry standards and use high-quality materials to ensure the structural integrity and longevity of the framing."
      },
      {
        question: "Can you provide custom framing solutions?",
        answer: "Yes, we can design and build custom framing solutions tailored to your specific project requirements."
      },
      {
        question: "What is the typical timeframe for a framing project?",
        answer: "The timeframe varies depending on the project's complexity, but we strive to complete all framing projects efficiently and on schedule."
      }
    ]
  },
  {
    id: uuidv4(),
    img: roofing,
    icon: "roof",
    title: "Professional Roofing",
    desc: "Comprehensive roofing solutions from installation to repair, ensuring top-notch quality and durability.",
    slug: "professional-roofing",
    detail_title: "Top-Notch Roofing Solutions",
    detail_paragraph: "Our professional roofing services cover everything from installation to repair. We use high-quality materials to ensure your roof is durable and reliable.",
    quality_title: "Quality Construction",
    quality_paragraph: "Our roofing projects are completed with high standards of workmanship, ensuring your roof provides long-lasting protection.",
    what_you_receive_title: "What You Receive?",
    what_you_receive_paragraph: "Reliable and durable roofing solutions tailored to your specific needs. Our team uses high-quality materials and proven techniques to ensure your roof withstands the test of time and weather.",
    quote: "Trust our expert roofing services for installations and repairs that protect your property.",
    img2: roofing1,
    img3: roofing2,
    faqArray: [
      {
        question: "What types of roofing materials do you use?",
        answer: "We use various roofing materials including asphalt shingles, metal, and tile to suit your specific needs and preferences."
      },
      {
        question: "How often should I have my roof inspected?",
        answer: "We recommend having your roof inspected at least once a year, or after severe weather events to ensure it remains in good condition."
      },
      {
        question: "Can you repair my existing roof?",
        answer: "Yes, we offer comprehensive repair services to address any issues with your existing roof and extend its lifespan."
      },
      {
        question: "What is the typical lifespan of a new roof?",
        answer: "The lifespan of a new roof varies depending on the materials used, but most roofs last between 20 to 50 years with proper maintenance."
      }
    ]
  },
  {
    id: uuidv4(),
    img: interior,
    icon: "interior",
    title: "Interior Finishing",
    desc: "Transforming interiors with exceptional craftsmanship and attention to detail, from drywall to custom finishes.",
    slug: "interior-finishing",
    detail_title: "Exceptional Interior Finishing",
    detail_paragraph: "Our interior finishing services transform your spaces with exceptional craftsmanship and attention to detail. From drywall to custom finishes, we bring your vision to life.",
    quality_title: "Quality Construction",
    quality_paragraph: "We pay meticulous attention to detail in all our interior finishing projects, ensuring your space looks and feels perfect.",
    what_you_receive_title: "What You Receive?",
    what_you_receive_paragraph: "Comprehensive interior finishing services that transform your space into a stylish and functional environment. Our attention to detail ensures that every aspect of your interior meets the highest standards of quality.",
    quote: "Transform your interiors with our exceptional finishing services, tailored to your needs.",
    img2: interior1,
    img3: interior2,
    faqArray: [
      {
        question: "What interior finishing services do you offer?",
        answer: "We offer a wide range of services including drywall installation, painting, trim work, and custom finishes to enhance your interior spaces."
      },
      {
        question: "Can you help with design and material selection?",
        answer: "Yes, we can assist with design and material selection to ensure your interior finishing project meets your vision and needs."
      },
      {
        question: "How long does an interior finishing project take?",
        answer: "The duration of the project depends on the scope and complexity, but we strive to complete all projects efficiently while maintaining high quality."
      },
      {
        question: "Do you offer warranty on your interior finishing work?",
        answer: "Yes, we offer warranties on our interior finishing work to ensure your satisfaction and peace of mind."
      }
    ]
  },
  {
    id: uuidv4(),
    img: concrete,
    icon: "concrete",
    title: "Concrete Services",
    desc: "Robust concrete solutions for all scales of construction projects, delivered on time and to specification.",
    slug: "concrete-services",
    detail_title: "Robust Concrete Solutions",
    detail_paragraph: "Our concrete services provide strong foundations for your construction projects. We deliver on time and to specification, ensuring high-quality results every time.",
    quality_title: "Quality Construction",
    quality_paragraph: "We ensure that our concrete projects are built to last, using the best materials and techniques available.",
    what_you_receive_title: "What You Receive?",
    what_you_receive_paragraph: "Durable and high-quality concrete services that ensure the strength and longevity of your construction projects. Our team delivers on time, maintaining the highest standards of workmanship.",
    quote: "Rely on our concrete services for robust solutions that meet all your construction needs.",
    img2: concrete1,
    img3: concrete2,
    faqArray: [
      {
        question: "What types of concrete services do you offer?",
        answer: "We offer a variety of concrete services including foundations, driveways, patios, and walkways for residential and commercial projects."
      },
      {
        question: "How long does concrete take to cure?",
        answer: "Concrete typically takes 24 to 48 hours to set and about 28 days to fully cure, depending on weather conditions and project specifications."
      },
      {
        question: "Do you provide custom concrete solutions?",
        answer: "Yes, we can create custom concrete solutions tailored to your specific project needs and design preferences."
      },
      {
        question: "How do you ensure the quality of your concrete work?",
        answer: "We use high-quality materials and follow industry best practices to ensure our concrete projects are durable and meet the highest standards of quality."
      }
    ]
  },
  {
    id: uuidv4(),
    img: concrete,
    icon: "many",
    title: "Many more!",
    desc: "Don't hesitate to contact us at (289) 887 4695 to inquire about our full range of services and discover how we can assist!",
    slug: null,
    detail_title: "Discover Our Full Range of Services",
    detail_paragraph: "We offer a wide range of construction services tailored to meet your specific needs. Contact us to learn more about how we can assist with your next project.",
    quality_title: "Quality Construction",
    quality_paragraph: "Our diverse range of services is executed with the same high standards of quality and attention to detail.",
    what_you_receive_title: "What You Receive?",
    what_you_receive_paragraph: "A broad spectrum of construction services designed to cater to diverse needs. Our commitment to quality and customer satisfaction ensures that we deliver exceptional results on every project.",
    quote: "Contact us today to learn more about our comprehensive range of services and how we can help with your next project.",
    faqArray: [
      {
        question: "What other services do you offer?",
        answer: "We offer a wide range of construction services including plumbing, electrical work, HVAC, and more. Contact us to learn about all our services."
      },
      {
        question: "How can I get a quote for my project?",
        answer: "You can contact us at (289) 887 4695 or visit our website to request a quote for your project. We will provide a detailed estimate based on your requirements."
      },
      {
        question: "Do you offer project management services?",
        answer: "Yes, we offer comprehensive project management services to ensure your project is completed on time and within budget."
      },
      {
        question: "Can you handle both residential and commercial projects?",
        answer: "Yes, we have the expertise and experience to handle both residential and commercial construction projects of any scale."
      }
    ]
  }
];

export default servicesTwoData;


