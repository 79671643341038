import { isValidElement } from "react";
import { v4 as uuidv4 } from "uuid";

const faqOneData = [
  {
    id: uuidv4(),
    ques: "What steps are involved in starting a new construction project?",
    ans: "Starting a new construction project involves several key steps: initial consultation, site assessment, project scope definition, budgeting, architectural design, and obtaining necessary permits before construction can begin.",
    index: "faq1",
  },
  {
    id: uuidv4(),
    ques: "Do you offer custom design services for unique projects?",
    ans: "Yes, we specialize in custom design services to meet unique project requirements. Our team works closely with clients to create tailored solutions that reflect their vision and needs.",
    index: "faq2",
  },
  {
    id: uuidv4(),
    ques: "How long does a typical construction project take to complete?",
    ans: "The duration of a construction project depends on its complexity and size. We work diligently to meet deadlines while ensuring high-quality standards are maintained throughout the project lifecycle.",
    index: "faq3",
  },
  {
    id: uuidv4(),
    ques: "What kind of post-construction services do you offer?",
    ans: "After construction, we provide a range of post-construction services including project clean-up, systems testing, and final walkthroughs to ensure everything meets your satisfaction and any necessary adjustments are made.",
    index: "faq4",
  },
  {
    id: uuidv4(),
    ques: "How do you handle changes or modifications during the construction process?",
    ans: "We understand that changes may be required during construction. We handle modifications through a structured change order process, ensuring any impacts on time or budget are clearly communicated and approved by you.",
    index: "faq5",
  },
];

export default faqOneData;
