import React from "react";
import { Link } from "react-router-dom";
import aboutImg from "../../assets/img/home2/about-cover.jpg";

const AboutTwo = () => {
  return (
    <section className="about-section section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-12 pe-xl-0">
            <div
              className="about-cover-bg bg-cover me-xl-5"
              style={{ backgroundImage: `url(${aboutImg})` }}
            >
              <div className="our-experience-years">
                <div className="year-outline">
                  <h2>20+</h2>
                </div>
                <p>
                  Years of <span>Expertise</span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-6 mt-5 mt-lg-0 col-12">
            <div className="block-contents">
              <div className="section-title">
                <span>About Us</span>
                <h2>Building Your Visions, Crafting Your Dreams</h2>
              </div>
              <blockquote>
                At 2brothers, we transform spaces with a comprehensive range of
                services from renovations to new construction and roofing.
              </blockquote>
            </div>

            <p>
              Whether it’s revamping your home or constructing from the ground
              up, we bring over a decade of experience to every project. We’re
              committed to quality craftsmanship and building lasting
              relationships with our clients.
            </p>

            <Link to="/about" className="theme-btn mt-35">
              Read More
            </Link>
          </div>
        </div>
      </div>
      <h2 className="section-rotate-title d-none d-xxl-block">ABOUT</h2>
    </section>
  );
};

export default AboutTwo;
