import React from "react";
import img1 from "../../assets/img/why-bg.jpg";

const WhyUs = () => {
  return (
    <section className="why-choice-us section-padding">
      <div
        className="why-choice-bg bg-cover d-none d-xl-block"
        style={{ backgroundImage: `url(${img1})` }}
      ></div>
      <div className="container">
        <div className="row">
          <div className="offset-xl-6 offset-lg-4 col-lg-8 col-xl-6 col-12">
            <div className="block-contents">
              <div className="section-title">
                <span>Why Choose Us</span>
                <h2>Top Choice For Construction</h2>
              </div>
            </div>
            <div className="single-icon-circle-item">
              <div className="icon">
                <i className="flaticon-cpu"></i>
              </div>
              <div className="contents">
                <h3>Unmatched Expertise</h3>
                <p>
                  At 2brothers, our deep industry knowledge and rigorous
                  standards ensure that every project is executed with precision
                  and excellence, tailored to meet each client's unique needs.
                </p>
              </div>
            </div>
            <div className="single-icon-circle-item">
              <div className="icon">
                <i className="flaticon-secure-shield"></i>
              </div>
              <div className="contents">
                <h3>Trust and Reliability</h3>
                <p>
                  Our reputation for trustworthiness and reliability is built on
                  decades of consistent quality, transparent processes, and a
                  commitment to client satisfaction. We deliver on our promises,
                  every time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
