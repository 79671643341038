import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../assets/img/project-tab.jpg";
import img2 from "../../assets/img/project-tab2.jpg";
import img3 from "../../assets/img/project-tab3.jpg";
import img4 from "../../assets/img/project-tab4.jpg";

function ProjectsTab() {
  return (
    <section className="our-info-tabs-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-12">
            <div className="project-tabs-wrapper">
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-tab1"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-tab-1"
                    type="button"
                    role="tab"
                    aria-controls="pills-tab-1"
                    aria-selected="true"
                  >
                    <i className="flaticon-notebook"></i> Service Range
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-tab2"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-tab-2"
                    type="button"
                    role="tab"
                    aria-controls="pills-tab-2"
                    aria-selected="false"
                  >
                    <i className="flaticon-construction-tool-vehicle-with-crane-lifting-materials"></i>{" "}
                    Professionals
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-tab3"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-tab-3"
                    type="button"
                    role="tab"
                    aria-controls="pills-tab-3"
                    aria-selected="false"
                  >
                    <i className="flaticon-operation"></i> Equipment
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-tab4"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-tab-4"
                    type="button"
                    role="tab"
                    aria-controls="pills-tab-4"
                    aria-selected="false"
                  >
                    <i className="flaticon-bar-chart"></i> Competitive Pricing
                  </button>
                </li>
              </ul>

              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-tab-1"
                  role="tabpanel"
                  aria-labelledby="pills-tab-1"
                >
                  <div className="tab-content-wrapper">
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <h2>Comprehensive Construction Services</h2>
                        <p>
                          From the initial blueprint to the final touches, our
                          comprehensive construction services ensure precision
                          and quality at every step. We specialize in a broad
                          range of projects, including residential, commercial,
                          and industrial buildings.
                        </p>
                        {/* <div className="data-flex">
                          <div className="single-data">
                            <p>POF</p>
                            <span>Elemental</span>
                            <h6>
                              24.658 <span>Mon</span>
                            </h6>
                          </div>
                          <div className="single-data">
                            <p>HUI</p>
                            <span>Special</span>
                            <h6>
                              224.658 <span>Year</span>
                            </h6>
                          </div>
                        </div> */}
                        {/* <Link to="/projectDetails">
                          Read More <i className="fas fa-long-arrow-right"></i>
                        </Link> */}
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="tab-img">
                          <img src={img1} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-tab-2"
                  role="tabpanel"
                  aria-labelledby="pills-tab-2"
                >
                  <div className="tab-content-wrapper">
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <h2>Skilled Professionals at Work</h2>
                        <p>
                          Our team consists of highly skilled professionals,
                          each with years of experience in the construction
                          industry. We are dedicated to turning every client's
                          vision into a reality through meticulous planning and
                          exceptional execution.
                        </p>
                        {/* <div className="data-flex">
                          <div className="single-data">
                            <p>POF</p>
                            <span>Elemental</span>
                            <h6>
                              24.658 <span>Mon</span>
                            </h6>
                          </div>
                          <div className="single-data">
                            <p>HUI</p>
                            <span>Special</span>
                            <h6>
                              224.658 <span>Year</span>
                            </h6>
                          </div>
                        </div> */}
                        {/* <Link to="/projectDetails">
                          Read More <i className="fas fa-long-arrow-right"></i>
                        </Link> */}
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="tab-img">
                          <img src={img2} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-tab-3"
                  role="tabpanel"
                  aria-labelledby="pills-tab-2"
                >
                  <div className="tab-content-wrapper">
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <h2>State-of-the-Art Equipment</h2>
                        <p>
                          Equipped with the latest technology and
                          state-of-the-art machinery, we ensure that all
                          projects are handled efficiently, meeting modern
                          standards for safety and precision in construction.
                        </p>
                        {/* <div className="data-flex">
                          <div className="single-data">
                            <p>POF</p>
                            <span>Elemental</span>
                            <h6>
                              24.658 <span>Mon</span>
                            </h6>
                          </div>
                          <div className="single-data">
                            <p>HUI</p>
                            <span>Special</span>
                            <h6>
                              224.658 <span>Year</span>
                            </h6>
                          </div>
                        </div> */}
                        {/* <Link to="/projectDetails">
                          Read More <i className="fas fa-long-arrow-right"></i>
                        </Link> */}
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="tab-img">
                          <img src={img3} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-tab-4"
                  role="tabpanel"
                  aria-labelledby="pills-tab-2"
                >
                  <div className="tab-content-wrapper">
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <h2>Competitive Pricing Strategy</h2>
                        <p>
                          We pride ourselves on offering competitive pricing
                          without compromising on quality. Our transparent
                          pricing model ensures that every client receives the
                          best value, tailored to their specific needs and
                          budget.
                        </p>
                        {/* <div className="data-flex">
                          <div className="single-data">
                            <p>POF</p>
                            <span>Elemental</span>
                            <h6>
                              24.658 <span>Mon</span>
                            </h6>
                          </div>
                          <div className="single-data">
                            <p>HUI</p>
                            <span>Special</span>
                            <h6>
                              224.658 <span>Year</span>
                            </h6>
                          </div>
                        </div> */}
                        {/* <a href="project-details.html">
                          Read More <i className="fas fa-long-arrow-right"></i>
                        </a> */}
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="tab-img">
                          <img src={img4} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectsTab;
